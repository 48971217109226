import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedServicesModule } from '../shared-services/shared-services.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedMaterialModule } from '../shared-material/shared-material.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';
import { RouterModule } from '@angular/router';
import { DesktopCategoryMenuComponent } from '../../components/desktop/desktop-category-menu/desktop-category-menu.component';
import { DesktopSearchComponent } from '../../components/desktop/desktop-search/desktop-search.component';
import { SharedSearchModule } from '../search/shared-search.module';

@NgModule({
  imports: [
    CommonModule,
    SharedServicesModule,
    SharedComponentsModule,
    InfiniteScrollModule,
    SharedMaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    RouterModule,
    SharedSearchModule,
  ],
  declarations: [
    DesktopCategoryMenuComponent,
    //DesktopSearchComponent,
  ],
  exports: [
    DesktopCategoryMenuComponent,
    InfiniteScrollModule,
    FlexLayoutModule,
  ]
})
export class DesktopMenuModule {
}
